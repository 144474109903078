
export function hasMobile() {
  try {
    document.createEvent("TouchEvent"); return true;
  } catch (e) {
    return false;
  }
}

export function getImgPath(name) {
  if (!name) return
  try {
    const path = `/src/assets/img/${name}`;
    const modules = import.meta.globEager("/src/assets/img/*");
    return modules[path].default;
  } catch (error) {
    return
  }
}

export function getContentHeight(value) {
  return document.documentElement.clientHeight - value;
}